import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'



const HeaderSocials = () => {
  return (
    <div className='header__socials'>
<a href="https://www.linkedin.com/in/cyril-thomas-ba238357/" target="https://www.linkedin.com/in/cyril-thomas-ba238357/">
    <BsLinkedin/>
</a>
        <a href="https://github.com/cysavage" target="_https://github.com/cysavage"><FaGithub/></a>
     
    </div>
  )
}

export default HeaderSocials