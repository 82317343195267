import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/av1.jpg'
import AVTR3 from '../../assets/avatar2.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// Core modules imports are same as usual
import {Pagination} from 'swiper/modules';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles must use direct files imports
import 'swiper/css'; // core Swiper
import 'swiper/css/pagination'; // Pagination module



const data = [
  {
    avatar: AVTR3,
    name: 'Danny Fellin',
    review: 'Cyrils attention to detail is truly commendable. Whether its analyzing data, crafting reports, or managing complex projects, he consistently demonstrates a keen eye for precision. His dedication to ensuring that every aspect of his work is thorough and accurate has not only contributed to the success of our projects but has also set a high standard for the entire team. His contributions have undoubtedly made a positive impact on our team and projects.'
  },
  {
    avatar: AVTR2,
    name: 'Ken Vadnais',
    review: 'Working alongside Cyril Thomas has been a delight. As an entry-level developer, he has impressed us with a rapid grasp of coding fundamentals and an eagerness to learn. Cyril navigated challenges with a positive attitude, contributing actively to our projects. The ability to swiftly adapt to new technologies and a commitment to collaboration make him a promising addition to any team. Im confident Cyril will continue to excel in their software development journey'
  },
  {
    avatar: AVTR3,
    name: 'Cowan Consulting - Internship',
    review: 'Cyril Thomas - during this internship was A level. Continue to evolve and grow. You have done an outstanding job to date organizing them etc. It has been a pleasure to watch your growth'
  },
  {
    avatar: AVTR4,
    name: 'Ariana M',
    review: 'In addition to Cyrils technical skills, Cyril has exhibited effective communication and teamwork. He is not only receptive to feedback but also provides valuable insights during team discussions. His ability to work harmoniously with colleagues makes him a valuable team player. Overall, I believe Cyril would be a valuable asset for any entry-level web development position. He possesses the foundational skills and eagerness to grow that are crucial for success in the ever-evolving field of web development. I recommend Cyril with confidence and enthusiasm for his future endeavors in web development.'
  },
]






const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>


      <Swiper pagination={true} modules={[Pagination]} className="container testimonials__container" spaceBetween={40}
        slidesPerView= {1}

         >

        {
          data.map(({avatar, name, review}, index) =>{
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__avatar'>
                  <img src = {avatar} />
                  </div>
                  <h5 className='client__name'>{name}</h5>
                  <small className='client__review'>
                  {review}
                  </small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
      </section>
  )
}

export default Testimonials