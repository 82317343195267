import React from 'react'
import './contact.css'
import{MdOutlineEmail} from 'react-icons/md'
import{RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_q9k0eyp','template_v0ait5d',form.current, 'B5NSHlWSTrln40b2X')

    e.target.reset()
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>cyrilkthomasest.1991@gmail.com</h5>
            <a href="mailto:cyrilkthomasest.1991@gmail.com">Send a message</a>
          </article>

          <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Cyril K. Thomas</h5>
            <a href="https://m.me/luckynumbersinmycereal">Send a message</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5></h5>
            <a href="https://www.linkedin.com/messaging/thread/2-ZGFiNWVjMjEtYzE0OS00YmQ4LWE4NTEtNjg3NWNjY2MxNDUyXzAxMg==/">Send a message</a>
          </article>
        

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <a href="https://api.whatsapp.com/send?phone=+15167846338">Send a message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}

        <form ref={form} onSubmit={sendEmail}>
        <input type="text" name ='name' placeholder = 'Your Full Name' required />
        <input type="email" name = 'email' placeholder = 'Your Email' required />
        <textarea name = "message" rows="7" placeholder ='Your Message' required></textarea>
        <button type ='submit' className='btn btn-primary'>Send Message</button>

        </form>
      </div>
    </section>
  )
}

export default Contact