import React from 'react'
import './about.css'
import ME from '../../assets/personal.jpg'
import SIDE from '../../assets/cyril.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

    <div className="container about__container">
      <div className="about__me">
        <div className="about__me-image">
          <img src ={ME} alt ="About Image"/>
        </div>
      </div>
      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>0-4 Years Working Experience</small>
          </article>

          
          <article className='about__card'>
            <VscFolderLibrary className='about__icon'/>
            <h5>Projects</h5>
            <small>20+ Completed</small>
          </article>
        </div>
<div>
        <p>



👋 Greetings! I'm Cyril K. Thomas, an aspiring Full Stack Developer fueled by a fascination for the intricate dance between front-end and back-end technologies. My journey kicked off with HTML, CSS, and JavaScript, shaping user-friendly interfaces. Venturing deeper, I'm now immersed in React for dynamic experiences and leveraging Express.js to navigate the server-side landscape.

💡 Exploring the realms of databases, I find joy in managing data with both SQL and MongoDB. As I weave through this tech tapestry, I'm excited about deploying applications on platforms like Heroku and AWS.

🚀 Beyond coding, I stay on the pulse of industry trends, attending meetups to connect with fellow developers. Collaboration is my forte, and I thrive on continuous learning and embracing new challenges.

🌐 Open to growth opportunities, I'm eager to contribute to exciting projects and connect with like-minded developers. Let's collaborate and create something extraordinary in the world of web development! 💻✨
        </p>
</div>
        <a href="#contact" className='btn btn-primary'> Let's Talk </a>

      </div>
    </div>
    </section>
  )
}

export default About