import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.gif'
import IMG3 from '../../assets/port4.jpg'
import IMG4 from '../../assets/stream.png'
import IMG5 from '../../assets/reactport.png'
import IMG6 from '../../assets/realestate.jpg'


const data =[
  {
    id: 1,
    image: IMG1,
    title: 'Vibeguide',
    body: 'Vibeguide is a web application that helps users discover the best-kept secrets of Long Island effortlessly. ',
    Languages: ' Languages: [PHP, HTML, JavaScript, CSS, Mapbox API]',
    github: 'https://github.com/cysavage/Vibe-Guide-Web-Application'
  },

  {
    id: 3,
    image: IMG3,
    title: 'Bakery Ordering System',
    body: 'The bakery ordering system creates a  seamless user experience for customers and helps bakery employees run their operations more efficiently. ',
    Languages: 'Languages: [Java, JavaFX, Firebase, Rest API]',
    github: 'https://github.com/mikelanasa/CSC325_Group4'
  },

  {
    id: 2,
    image: IMG2,
    title: 'Bakery Ordering/Inventory System',
    body: 'The BOSS system is a comprehensive software solution intended for bakeries to allow ordering to be done on a system.  ',
    Languages: 'Languages: [Java,JavaFX, Microsoft Access as DB]',
    github: 'https://github.com/01LiDev/Bakery-Ordering-Inventory-System'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Streaming Bundling System',
    body: 'The streaming bundling system creates a user system that allows user to register/login and be able to choose bundling streaming packages that generates discounts based on packaging. System keeps tracks of each users subscription packages ',
    Languages: 'Languages: [Java, Spring, JavaFX, AccessDB]',
    github: 'https://github.com/cysavage/StreamBundleSystemJava'
  },
  {
    id: 5,
    image: IMG5,
    title: 'React Portfolio',
    body: 'WebPortfolio created to Host Resume, Display Skills, Projects and use Javascript Mailbox API to Contact, Host & Connect Social Media Assets - ',
    Languages: 'Languages: [React, Javascript, DOM, Hostzinger Web-Hosting]',
    github: 'https://github.com/cysavage/https---github.com-cysavage-CurrentPortfolioReact'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Realestate -React Intern Website',
    body: 'Realestate React Website Created For Users/Realtors to Purchase/Host Properties',
    Languages: 'Javascript, React,React DOM, Firebase, GoogleMap API',
    
  },
]


const Portfolio = () => {
  return (
    <section id ='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image,title, body, Languages, github}) => {
            return(
         
              <article className='portfolio__item'>

        <div className='portfolio__item-image'>
        <img src ={image} alt =""/>
        </div>
        <h2>{title}</h2>
        <h3>{body}</h3>
        <h4>{Languages}</h4>
      
        <div className="portfolio__item-cta">
        <a href={github} className='btn'>Github</a>
    
        </div>
        </article>

            )
          })
        }
      </div>
      </section>
  )
}

export default Portfolio